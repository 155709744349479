@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

main.home-page section.vehicle-live-auction .swiper-horizontal {
  padding: $margin-xs * 5 0px;
  height: 350px;
}

.swiper-pagination-bullet {
  border: 1px solid $gray;
  background-color: $white;
  opacity: 1;
  padding: $padding-sm;
}

.swiper-pagination-bullet-active {
  background-color: $RoyalBlue;
}

.carousel-thumb {
  background: $tertiary-color 0% 0% no-repeat padding-box;
  padding: $padding-xs * 5 $padding-lg;
  text-align: center;

  h4 {
    font-size: 40px;
    color: $primary-color;
    @include mq(lg) {
      font-size: 26px;
    }
  }
  figure {
    img {
      max-height: 100px;
    }
    figcaption {
      color: $black-50;
      font-size: 25px;
      font-weight: normal;

      @include mq(lg) {
        font-size: 23px;
      }
      small {
        font-size: 14px;
        display: block;
      }
    }
  }
}
