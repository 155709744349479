@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.make {
  min-width: 100px;
  background-color: $alert-red;
}
main.home-page {
  overflow-x: hidden;
  .banner-area {
    img {
      height: 100vh;
      width: 100%;
      margin-top: -4%;

      @include mq(md) {
        display: none;
      }
    }
    .image-text {
      right: 0;
      top: 10%;
      position: absolute;
      text-align: end;
      padding-right: $padding-md * 4;
      display: flex;
      justify-content: flex-end;
      color: $white;
      h1 {
        font-family: $secondary-font;
        font-weight: bold;
        font-size: $font-lg * 3;
        margin-top: $margin-xl;
      }
      .banner-title {
        font-family: $secondary-font;
        margin-top: $margin-lg;
      }

      .banner-tagline {
        text-shadow: $gray 2px 0 10px;

        @include mq(lg) {
          display: none;
        }
      }

      @include mq(md) {
        display: none;
      }
    }

    .ymmsection {
      padding: $padding-xs * 5;
      background: $primary-color;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 50;
      width: 100%;
      bottom: 0;
      @include mq(sm) {
        position: relative;
      }
      @include mq(lg) {
        padding-top: 0;
      }
    }

    .sticky {
      position: fixed;
      height: fit-content;
      @include mq(sm) {
        position: relative;
      }
    }

    .car-selection-area {
      justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      @include mq(lg) {
        max-width: 100%;
        margin-top: $margin-lg;
        justify-content: start;
      }
      select {
        margin: $margin-xs;
        flex: 1;
        transition: all 0.3s ease-out;

        @include mq(md) {
          flex: 0 0 calc(50% - 10px);
        }
        @include mq(xs) {
          flex: 0 0 calc(100% - 10px);
        }

        &:disabled {
          box-shadow: none;
          background-color: $white-20;
          color: #c6c6c6;
        }

        &.year {
          max-width: 90px;
          @include mq(md) {
            max-width: 100%;
          }
        }
      }
      button.btn-outline {
        height: 44px;
        min-height: 44px;
        @include mq(md) {
          margin: 0 auto 0;
        }
        @include mq(sm) {
          font-size: 16px;
        }
        @include mq(xs) {
          width: 100%;
        }
      }
    }
  }

  .carinventory {
    background-color: $teal-50;

    h2 {
      font-family: $secondary-font;
      font-size: 40px;
      font-weight: 550;
    }
    h4 {
      font-size: 28px;
      font-weight: 500;
    }
    h1 {
      font-family: $secondary-font;
      color: $black-50;
      font-weight: bold;
      font-size: 50px;
      text-align: center;
      margin-top: $margin-xl;
    }
    color: $Blue;
  }
  .mobilesCarousel {
    color: $black-50;
    &.v-shape-bg {
      background: transparent
        linear-gradient(
          320deg,
          $white 10%,
          $background-7 50%,
          $background-6 100%
        )
        0% 0% no-repeat padding-box;
      clip-path: polygon(50% 0, 100% 5%, 100% 100%, 0 100%, 0 5%);
    }
    h1 {
      font-size: $font-lg * 3;
      font-family: $secondary-font;
      color: $black-50;
      text-align: center;
    }

    h2 {
      font-size: $font-xl;
      font-family: $tertiary-font;
      color: $black-50;
    }
    .icon {
      width: 80px;
      height: 80px;
    }
  }

  section {
    padding: $margin-xl 0;
    @include mq(lg) {
      padding: $padding-xl 0;
    }
    @include mq(md) {
      padding: $padding-lg 0;
    }

    header {
      margin-bottom: $margin-xl;
      @include mq(lg) {
        margin-bottom: $margin-lg;
      }
      h2 {
        color: $Blue;
        @include mq(lg) {
          font-size: $margin-xl;
        }
        @include mq(md) {
          font-size: 45px;
        }
        @include mq(xs) {
          font-size: 40px;
        }
        @include mq(xss) {
          font-size: 27px;
          line-height: 38px;
        }
      }
      h4 {
        color: $Blue;
        font-weight: normal;

        @include mq(lg) {
          font-size: 22px;
        }
        @include mq(sm) {
          font-size: 20px;
        }
        @include mq(xs) {
          font-size: 15px;
        }
        &.mt-4 {
          @include mq(lg) {
            margin-top: $margin-md !important;
          }
        }
      }
    }

    &.vehicle-type-search {
      &.v-shape-bg {
        padding-bottom: $padding-xl * 3;
        @include mq(lg) {
          padding-bottom: $padding-xl * 2;
        }
        @include mq(sm) {
          padding-bottom: $padding-xs * 2;
        }
      }
    }

    .home-card {
      background: transparent linear-gradient(180deg, #19157b 0%, #090926 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000004d;
      border: 2px solid $white;
      border-radius: 8px;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include mq(lg) {
        min-height: 180px;
      }
      @include mq(sm) {
        margin-bottom: $padding-xs * 5;
        min-height: 80px;
      }
      h3 {
        color: $white;
        font-weight: normal;
        @include mq(md) {
          font-size: 22px;
        }
      }
    }

    &.payment-calculator {
      margin-top: $margin-xl;
      h1 {
        margin-bottom: $margin-xl * 2;
        font-weight: bold;
        font-size: 60px;
        text-align: center;
        font-style: italic;
        color: $Blue;
      }
      .payment-calculator-area {
        max-width: 460px;
        margin: 20px auto;
      }
      img.rounded-circle {
        max-width: 400px;
        @include mq(sm) {
          max-width: 100%;
        }
      }

      .loanCalulator-dark .heading span {
        font-weight: 500;
        font-size: 2em;
      }
      .find-budget {
        h2 {
          font-family: $secondary-font;
          margin-top: $margin-lg;
          font-weight: 550;
        }
        h6 {
          font-size: 1.2em;
        }
        h5 {
          margin-top: $margin-sm;
          font-size: 1.5em;
          color: $primary-color;
          @include mq(xs) {
            // text-align: left;
          }
        }

        ul {
          li {
            list-style: none;
            position: relative;
            padding-left: $padding-xs * 5;
            margin-bottom: $margin-xs;
            font-weight: normal;

            &::before {
              content: "\2022";
              color: $black-50;
              font-size: $margin-xl;
              line-height: 0;
              position: absolute;
              left: 0;
              top: 7px;
            }
          }
        }
      }
    }

    .shadow-box {
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 12px $box-shadow;
      padding: $padding-sm * 5;

      @include mq(xs) {
        padding: $padding-xs * 2;
      }

      .car-type-list {
        display: flex;
        flex-wrap: wrap;
        li {
          flex: 0 0 33.3%;
          list-style: none;
          text-align: center;
          margin: $margin-md 0;
          cursor: pointer;
          transition: all 0.3s;
          display: flex;
          flex-flow: column;
          align-items: center;
          @include mq(sm) {
            flex: 0 0 50%;
          }
          @include mq(xs) {
            svg {
              width: 80%;
            }
          }
          h6 {
            color: $Blue;
            margin-top: 2px;
          }

          &:hover {
            svg:first-child {
              path {
                fill: $RoyalBlue;
              }
            }
          }
        }
      }
    }
    .car-type-price {
      background-color: $Background-1;
      padding: $padding-md * 2;
      height: 100%;

      @include mq(xs) {
        padding: $padding-xs * 2;
      }

      form {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 100%;
      }

      .total-vehicles {
        input {
          background: $white 0% 0% no-repeat padding-box;
          box-shadow: inset 0px 3px 6px $box-shadow;
          border-radius: 8px;
          border: none;
          padding: 10px 20px;
          text-align: center;
          font-size: 40px;
        }
        p {
          color: $RoyalBlue;
          text-align: center;
          margin-top: $margin-xs * 2;
        }
      }
      .select-box-area {
        select {
          margin: $margin-lg auto;
          max-width: 200px;
        }
      }
    }

    &.vehicle-live-auction {
      .swiper-horizontal {
        padding: $margin-xs * 5 0px;
      }
    }
    h3 {
      font-family: $secondary-font;
      font-size: 4em;
    }
    h1 {
      font-weight: 550;
      font-size: 60px;
    }
    .vehicles-auction-count {
      color: $primary-color;
      text-align: center;
      img {
        height: 35px;
      }

      h1 {
        font-weight: 300;
        font-size: 60px;
      }

      @include mq(lg) {
        font-size: $margin-xl;
        line-height: 40px;
      }

      @include mq(md) {
        font-size: 40px;
        line-height: $padding-md * 2;
        margin-bottom: $margin-xs * 5;
      }
      @include mq(xs) {
        font-size: $padding-md * 2;
      }

      p {
        font-style: italic;
        font-weight: normal;
        display: block;
        color: $black-50;
        @include mq(lg) {
          font-size: 14px;
          line-height: 21px;
          margin-top: $margin-lg;
        }
      }
    }

    &.what-car {
      &.v-shape-bg {
        @include mq(md) {
          padding-bottom: $padding-lg * 5;
        }
        @include mq(sm) {
          padding-bottom: $padding-xl;
        }
        @include mq(xs) {
          padding-top: 0px;
        }
      }
    }

    &.testimonials {
      background-color: $primary-color;
      margin-top: $margin-xl;

      @include mq(md) {
        margin-top: 0;
        padding-top: $margin-xl;
      }
      @include mq(xs) {
        padding-top: $padding-md * 2;
      }

      header {
        h2 {
          font-size: $font-xl;
          font-family: $secondary-font;
          color: $white;
        }
      }

      .swiper-slide {
        width: 80%;
      }
      .swiper-horizontal {
        padding: $padding-xs * 2.5 $padding-md * 2;
      }
      .swiper-button-prev {
        left: 0;
        color: $red;
        text-shadow: 0px 2px 6px $box-shadow-2;
      }
      .swiper-button-next {
        right: 0;
        color: $red;
        text-shadow: 0px 2px 6px $box-shadow-2;
      }
    }
  }
  .girlInCar {
    h2 {
      font-family: $secondary-font;
    }
    li {
      font-weight: normal;
    }
    .cartext {
      line-height: 32px;
      background-color: $black-700;
      color: $white;
      height: 500px;
    }
    img {
      height: 500px;
    }
    h5 {
      color: $red;
      font-style: italic;
      font-family: $secondary-font;
    }
  }
}
