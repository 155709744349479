@import "@assets/Sass/variables";

.companyName {
  &.bold {
    font-weight: bold;
  }
  sup {
    font-size: 10px;
    top: -0.6em;
  }
  .firstName {
    color: $yellow-30;
  }
}
