@import "@assets/Sass/variables";

.formSelect {
  border-radius: 100px;
}
.iconColorSize {
  color: $coral-red;
  width: 12px;
  height: 12px;
  margin-right: $margin-sm;
}

:global {
  .input-group {
    .css-rnzp77-container,
    .css-u941er-container {
      padding-right: $padding-xs;
      font-weight: 600;
      border-top-left-radius: 100px !important;
      border-bottom-left-radius: 100px !important;
      border-top-right-radius: 100px !important;
      border-bottom-right-radius: 100px !important;
    }
    .css-1gq94g0-control,
    .css-11t1ub1-control,
    .css-9yoliy-control {
      border-radius: 100px;
      box-shadow: $payment-input-box-shadow;
      border: 1px solid $primary-border-color;
      &:hover {
        border-color: $primary-border-color;
      }
    }
    .css-u941er-container {
      padding-right: 0px;
    }
  }
}
.iconSvg {
  height: 30px;
  width: 30px;
}
