@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.spaceContainer {
  display: flex;

  @include mq(sm) {
    flex-wrap: wrap;
  }
  
  &.small {
    gap: 8px;
  }
  &.medium {
    gap: 16px;
  }

  &.large {
    gap: 24px;
  }
  &.extra-large {
    gap: 48px;
  }

  &.vertical {
    flex-wrap: wrap;
    > .spaceItem {
      width: 100%;
    }
  }
}
