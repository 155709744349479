@import "@assets/Sass/variables";

.modalBox {
  position: relative;
  border-radius: 0;
  :global {
    .modal-content {
      border: none;
      border-radius: 0;
    }
  }

  .title {
    font-size: 40px;
    font-weight: 500;
  }
  .subHeader {
    font-size: 18px;
    font-weight: normal;
  }

  .label {
    color: $gray-100;
    font-size: 14px;
    cursor: pointer;
    font-weight: normal;
    .terms{
      color: $primary-color;
      margin-left: $margin-xs;
    }
  }

  .paraDesc {
    color: $Blue;
    font-size: 18px;
    font-weight: bold;
  }
  .paraDesc1 {
    color: $gray-100;
    font-size: 14px;
    font-weight: normal;
  }
  .textBlue {
    color: $primary-color;
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
  }
  .socialButton {
    margin-top: $margin-xl;
    margin-bottom: $margin-lg;
  }
  .googleLogin {
    height: 50px;
    padding: 0;
    background: #f72424 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000033;
    opacity: 1;
    border: 2px solid #ffffff;
    border-color: #f72424;
    color: #fff;
    border-radius: 0;
    width: 100%;
    &:hover {
      border-color: #f72424;
      background-color: #fff;
      color: #f72424;
    }
  }
  .facebookLogin {
    height: 50px;
    padding: 0;
    background: #2963bb 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000033;
    opacity: 1;
    border: 2px solid #ffffff;
    color: #fff;
    border-color: #2963bb;
    border-radius: 0;
    width: 100%;
    &:hover {
      border-color: #2963bb;
      background-color: #fff;
      color: #2963bb;
    }
  }
  .appleLogin {
    height: 50px;
    padding: 0;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000033;
    border: 2px solid #ffffff;
    opacity: 1;
    color: #fff;
    border-radius: 0;
    border-color: #000000;
    width: 100%;

    &:hover {
      border-color: #000000;
      background-color: #fff;
      color: #000000;
    }
  }

  .showLine {
    display: flex;
    justify-content: baseline;
    justify-content: space-between;
  }
  .floatRight {
    float: right;
    margin-top: -2px;
  }
  .modalBody {
    margin: $margin-lg * 0.7;
    .buttonContainer {
      margin-top: $margin-lg;
    }
    &::-webkit-scrollbar {
      width: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }
  }
  @media screen and (max-width: 768px) {
    .displayBlockMobile {
      display: block;
      p {
        margin-top: $margin-sm;
      }
    }
    .modalBody {
      margin: 0px;
      border: #ffffff;
      height: 100vh;
    }
    .setCenter {
      justify-content: center;
      text-align: center;
    }
    .showLine {
      display: block;
      text-align: center;
    }
    .floatRight {
      text-align: center;
      float: none;
      margin-top: 5px;
    }
    .socialButton {
      margin-top: $margin-sm;
      .marginSm {
        margin-top: $margin-lg - 5px;
      }
    }
    .textBlue {
      margin-top: $margin-sm;
    }
    .modalHeader {
      height: 104px;
      position: sticky;
      top: 0;
      z-index: 9;
      h5 {
        font-weight: normal;
        font-size: 24px;
        display: block;
      }
      p {
        font-size: 14px;
      }
      .closeIcon {
        cursor: pointer;
        margin-bottom: 3rem;
        svg {
          margin-right: -$margin-sm;
          margin-top: $margin-sm;
        }
      }
    }
    .form {
      margin: 0px;
    }
    .personImage {
      display: none;
    }
  }
}
