@import "./variables";

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
aside,
figure,
figcaption,
ul,
address {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  &:hover {
    transition: all 0.2s;
  }
}

.list-none {
  li {
    list-style: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

/* custom css for overall card experience */
.card {
  border: none;
  border-radius: 0;

  .card-header {
    background: inherit;
    border: none;
    border-radius: 0;
    font-size: 20px;
    font-weight: bold;
    color: $Blue;
    border-bottom: 0.5px solid #20acf954;
  }
}
