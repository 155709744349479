@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  .auctionForm {
    border: none;
    margin: 20px 25px 25px 25px;
    height: calc(100% - 40px);
    @media screen and (max-width: 767px) {
      margin: 0 0 25px 0;
    }
  }
  .auctionProcess {
    height: 100%;
    border: none;
    min-height: 100vh;
  }
  .auctionProcessCol {
    min-height: 100vh;
  }
  .overlay {
    @include Overlay();
  }
}
