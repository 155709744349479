@import "./colors";

// colors
$Blue: #110f45;
$blue: #110f45;
$blue-1: #001441;
$blue-2: #002d91;
$blue-10: #205d9c;
$blue-20: #3291f4;
$dark-blue: #1a4c7f;
$blue-30: #030f25;
$blue-40: #002c8e;

$cyan: #00ccf3;
$BlueLight: #3497fd26;
$RoyalBlue: #3497fd;
$RoyalBlue1: #2e81f3;
$RoyalBlue2: #137ce9;
$LightBlue: #20acf9;
$LightBlue2: #bbdcfe;
$Border-Color: #114aab;
$Border-Color-1: #eeeeee;
$Border-Color-2: #707070;
$Border-Color-3: #b7b7b7;
$border-color-4: #dddddd;

$chalk: #bbbbbb;
$purple-1: #43425d;

$clock-background: #eeeeee80;
$clock-background-1: #20acf926;

$Background-1: #f8faff;
$Background-2: #34c0f0;
$Background-3: #fcfcfc;
$background-4: #20acf929;
$background-5: #37f0c6;
$background-6: #cfd2e1;
$background-7: #eff0f4;
$white01: #dddddd;
$HeadingColor1: #110f45;
$white-20: #fcfcfc;

$Box-Shadow-1: #24b6f733;
$text-error: #dc3545;
$alert-red: #ff0000;
$red-orange: #f72424;
$alert-red-background: #ff000026;
$background-color-checkbox: #f8faff;

$coral-red: #f97171;

$grey: #888888;
$grey01: #919c91;
$light-grey-10: #b7b9bd;
$lightGrey: #d3d3d3;
$grey-10: #c6c6c9;
$grey-20: #77767c;
$grey-10: #b0b0b0;

$yellow: #f8e00b;
$light-yellow: #eeb76a;
$yellow-10: #f5c483;
$yellow-20: #ffd504;
$yellow-30: #fecf3e;
$dark-yellow: #c98200;
$green: #68f558;
$dark-green: #00aa05;
$dark-green-1: #009a05;
$secondary-text: #0c0a31;
$navy-blue: #2963bb;

$cream: #dbae94;
$cream-10: #a47763;
$cream-20: #cda086;

$orange: #cb844a;
$orange-10: #8d4f2a;
$dark-orange: #ffa700;
$dark-orange-10: #a25727;

$light-blue-10: #8591ac;
$light-blue-20: #c8cedb;
$light-blue: #3e9cfd;
$light-blue-1: #20acf9;
$dark-blue: #3798f5;
$box-shadow: #00000029;
$box-shadow-2: #0000004d;

$card-box-shadow: 0px 3px 6px $box-shadow;
$accordion-header-box-shadow: 0px 2px 2px 2px $border-color-4;
$accordion-body-box-shadow: inset 0px 8px 10px $box-shadow;
$social-buttons-box-shadow: 0px 3px 8px #00000033;
$payment-input-box-shadow: 0px 3px 6px $gray-700;
$dealer-input-box-shadow: inset 0px 3px 3px $box-shadow;
$bullet-shadow: 0px 0px 5px 5px $light-grey-10;

$Color-1: #f8faff;

/* variables for spacing */

$margin-xs: 4px;
$margin-sm: 8px;
$margin-md: 16px;
$margin-lg: 24px;
$margin-xl: 48px;

$padding-xs: 4px;
$padding-sm: 8px;
$padding-md: 16px;
$padding-lg: 24px;
$padding-xl: 48px;
$backdrop-color: rgba(0, 0, 0, 0.5);

$gradient-footer: transparent linear-gradient(90deg, #05c9f0 0%, #2b67b7 100%)
  0% 0% no-repeat padding-box;
$gradient-dark-blue: transparent
  linear-gradient(180deg, #15118a 0%, #090946 100%) 0% 0% no-repeat padding-box;
$gradient-footer-wrapper: transparent
  linear-gradient(180deg, #110f45 0%, #03030e 100%) 0% 0% no-repeat padding-box;
$gradient-blue: linear-gradient(
    91deg,
    $cyan 0%,
    $LightBlue 38%,
    $navy-blue 100%
  )
  0% 0% no-repeat padding-box;

// font-family

$primary-font: "Barlow", sans-serif;
$secondary-font: "Cormorant Garamond", sans-serif;
$tertiary-font: "Source Sans Pro", sans-serif;

// font-size

$font-xs: 8px;
$font-sm: 14px;
$font-md: 18px;
$font-lg: 24px;
$font-xl: 40px;
