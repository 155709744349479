@import "@assets/Sass/variables";

@mixin ButtonHoverEffect() {
  &:hover {
    background-color: $black;
    color: $white;
    border: none;
  }
}

@mixin ButtonDesign($bgColor) {
  color: $white;
  border: none;
  border-radius: 100px;
  font-family: $primary-font;
  font-weight: 600;
  text-transform: uppercase;
  @include ButtonHoverEffect();

  &.outline {
    background-color: $white;
    border: 2px solid $bgColor;
    color: $bgColor;
    @include ButtonHoverEffect();
  }
}

.button {
  &.btn {
    &-lg {
      min-width: 200px;
    }
    &-sm {
      min-width: none;
      padding: $padding-sm $padding-md;
      font-size: $font-sm;
    }
  }

  &.filled {
    background: $RoyalBlue;
    color: $white;
    border: none;

    &.green {
      background: $dark-green-1;
    }
  }
  &.bordered {
    border: 2px solid $secondary-color;
  }

  &.primary {
    background-color: $primary-color;
    @include ButtonDesign($primary-color);
  }

  &.secondary {
    background-color: $secondary-color;
    @include ButtonDesign($secondary-color);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
