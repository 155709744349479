@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

header {
  &.main {
    .navbar {
      z-index: 102;
      padding-top: $padding-xs;
      padding-bottom: 0;
    }
    .bg-light {
      background-color: $white;
    }

    .navbar-brand {
      img {
        max-height: 40px;
      }
    }
    .navbar-dark .navbar-nav .nav-link {
      color: $black;
      position: relative;
      margin: 0 1px;
      @include mq(lg) {
        font-size: 13px;
      }
      @include mq(md) {
        font-size: 15px;
        margin: 0;
      }
    }
    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .show > .nav-link {
      color: $black;
      &:hover {
        color: $primary-color;
      }
    }

    .navbar-dark .navbar-toggler {
      border-color: transparent;
      .navbar-toggler-icon {
        background-image: url("../../Assets/Images/menu-icon.png");
      }
    }
    .navbar-toggler:focus {
      box-shadow: none;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      width: fit-content;
      @include mq(md) {
        padding: 10px 0;
      }
      @include mq(lg-min) {
        padding: 0 15px;
      }
    }

    .navbar-dark .navbar-toggler[aria-expanded="true"] {
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      .navbar-toggler-icon {
        background-image: url("../../Assets/Images/cross-mark.svg");
      }
    }
    .contact {
      border: 2px solid $black;
      border-radius: 50px;
      width: fit-content;

      @include mq(xs) {
        padding-left: $padding-xs;
      }
    }
    .user-area {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      position: relative;
      @include mq(md) {
        justify-content: center;
        padding-bottom: $padding-xs;
        padding: 0;
        justify-content: flex-start;
      }
      figure {
        position: relative;
        padding-bottom: $padding-md;
        padding-left: $padding-md;
        padding-top: $padding-sm;
        @include mq(md) {
          position: unset;
          padding-left: 0;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        figcaption {
          color: $white;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      ul {
        display: flex;
        margin-left: 20px;
        li {
          list-style: none;
          width: fit-content;
          a {
            color: $white;
            &:hover {
              opacity: 1;
              svg path {
                fill: $LightBlue;
              }
            }
          }
        }
      }
    }
  }
}
.dropdownMenuProfile {
  ul {
    display: block;
    position: relative;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #110f45;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;

    li {
      a {
        display: block;
        width: 100%;
        padding: 0.25rem 1rem;
        clear: both;
        font-weight: 400;
        color: #110f45;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        &:hover {
          background-color: #110f45;
          color: #fff;
        }
      }
    }
  }
}
