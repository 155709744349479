@import "@assets/Sass/variables";

.title {
  color: $primary-color;
}
.size {
  &-sm {
    font-size: $font-sm;
  }
  &-md {
    font-size: $font-md;
  }
  &-lg {
    font-size: $font-lg;
  }
  &-xl {
    font-size: $font-xl;
  }
}
