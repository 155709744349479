@import "@assets/Sass/variables";

.group {
  .inputLabel {
    margin-bottom: $margin-xs;
    font-size: 14px;
    font-weight: normal;


    &.bold {
      color: $secondary-color;
      font-size: inherit;
      font-weight: bold;
    }

    &.primary {
      color: $primary-color;
    }

    &.secondary {
      color: $secondary-color;
    }

    .astric {
      font-size: 12px;
      color: $text-error;
      margin-left: $margin-sm;
    }
  }
  .inputPrefix {
    position: absolute;
    background: none;
    border: none;
    font-weight: bold;
    top: 5px;
  }
  .error {
    font-weight: normal;
  }

  .search {
    z-index: 3;
    position: absolute;
    right: 10px;
    top: 4px;
    path {
      fill: $grey;
    }
    &:hover {
      cursor: pointer;
      path {
        fill: $Blue;
      }
    }
  }

  .tick {
    color: $secondary-color;

    &.invalid {
      color: $alert-red;
    }
    z-index: 3;
    position: absolute;
    right: 10px;
    top: 55%;
    .tickMarkSymbol {
      height: 30px;
      width: 30px;
    }
  }
}

.info {
  color: $grey;
}
input.inputField {
  border: 1px solid $Border-Color-1;
  box-shadow: 0px 9px 6px #c1bcbc33;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 2px;
  padding: $padding-sm $padding-md;
  // thanks to weird selector from bootstrap: .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback)ˀ
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;

  &::placeholder {
    color: $grey-10;
    font-weight: 500;
  }
  &:focus,
  &:active {
    color: $RoyalBlue;
  }

  &.hasPrefix {
    padding-left: $padding-lg;
  }
}
