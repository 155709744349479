@import "@assets/Sass/variables";

.sidePanelNav {
  width: 100%;
  height: 100%;
  background: $tertiary-color 0% 0% no-repeat padding-box;

  .sidePanelHeader {
    width: 100%;
    background: $secondary-color;
  }

  .listGroup {
    width: 100%;
    max-height: 85vh;
    border: none;
    li.listGroupItem {
      background: $white 0% 0% no-repeat padding-box;
      cursor: pointer;
      max-height: 70px;
      min-height: 70px;
      min-width: 190px;
      padding-top: $padding-xs * 2.5;
      border-bottom: none;
      padding-bottom: $padding-xs * 2.5;
      border: none;
      max-height: 200px;
      transition: all 0.1s ease-in-out;
      p.text-sm {
        font-size: 0.85em;
      }
      &.activePage {
        border: 3px solid $primary-color;
      }
      &.bgProduct {
        background: $tertiary-color 0% 0% no-repeat padding-box;
        &:hover {
          border: none;
        }
      }
      &:hover {
        padding-top: $padding-sm;
        padding-bottom: $padding-sm;
        border: 3px solid $primary-color;
      }
      .imageContainer {
        img {
          width: 50px;
          height: 50px;
          border-radius: 100%;
        }
      }
      .outerCircle {
        width: 21px;
        height: 21px;
        border-radius: 100%;
        border: 1px solid #3397fd;
      }
    }
    h6 {
      color: $black-700;
      font-weight: medium;
      font-size: $font-md;
    }
    .text-subtitle {
      color: $gray-100;
      font-weight: normal;
      font-size: $font-sm;
    }
  }

  .carImage {
    background: $tertiary-color 0% 0% no-repeat padding-box;
    width: 50px;
    border-radius: 100%;
    height: 50px;
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    border: 2px solid $secondary-gray;
  }
}
