@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.cookieCard {
  left: 15%;
  bottom: 0;
  width: 70%;
  z-index: 500;
  position: fixed;
  padding-top: -$padding-md;
  border-radius: $padding-sm;
  padding-bottom: -$padding-md;
  border: 2px solid rgba(#ccc, 0.6);

  .cookieBtnContainer {
    display: flex;
    align-self: center;
  }

  .cookieText {
    font-weight: 400;
    font-size: $margin-lg;
    margin-bottom: $padding-sm;
  }

  .acceptBtn {
    flex-grow: 1;
    min-width: $padding-sm * 25;
    &:first-child {
      margin-right: $margin-sm;
    }
  }

  @include mq(xxs) {
    left: 0;
    bottom: -8px;
    .acceptBtn {
      min-width: 140px;
    }
    .cookieBtnContainer {
      display: flex;
      justify-content: center;
    }
    .cookieText {
      font-size: 0.8em;
    }
  }

  @include mq(lg) {
    left: 0;
    width: 100%;
    bottom: -8px;
    .cardText {
      padding-left: $padding-md;
      padding-right: $padding-md;
    }
    .cardroot {
      display: flex;
      flex-wrap: wrap;
    }
    .popupcol {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .cookieBtnContainer {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @include mq(xss) {
    left: 0;
    width: 100%;
    bottom: -8px;
    .cardText {
      padding-left: $padding-md;
      padding-right: $padding-md;
    }
    .acceptBtn {
      min-width: 150px;
    }
    .cardroot {
      display: flex;
      flex-wrap: wrap;
    }
    .popupcol {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .cookieBtnContainer {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @include mq(sm) {
    left: 0;
    width: 100%;
    bottom: -8px;
    .cardText {
      padding-left: $padding-md;
      padding-right: $padding-md;
    }
    .cardroot {
      display: flex;
      flex-wrap: wrap;
    }
    .popupcol {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .cookieBtnContainer {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
