@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.parentThemeInput {
  width: 79%;
  display: flex;
  gap: 10px;
  @include mq(lg) {
    width: 100%;
  }

  @include mq(sm) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @include mq(md) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    float: left;
  }

  .otherInput {
    width: 15%;
    padding-right: $padding-sm;

    @include mq(sm) {
      width: 100% !important;
      margin: $margin-xs;
      padding: 0;
    }
  }
}

.btn-outline {
  @include mq(md) {
    display: flex;
    float: right;
  }
}

.updateBtn {
  min-height: $margin-sm;
  font-size: 14px;
  margin-left: $margin-sm;
  padding-right: $padding-md;
  margin-bottom: $margin-sm;

  span {
    margin-left: 0;
  }
}
.selectVehicle {
  width: 100%;
  .otherInput {
    @include mq(lg) {
      width: 18%;
    }
    padding: 0;
  }
  .updateBtn {
    width: 150px;
  }
}
