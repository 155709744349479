@import "@assets/Sass/variables";

.formik-form-container {
  .slider-root {
    color: $primary-color;
    height: 8px;
  }
  .slider-labels {
    font-weight: normal;
    font-size: 0.8em;
  }
  p.err-text {
    font-size: 0.8em;
    color: #cb4335;
  }

  .inputLabel {
    color: $grey;
    margin-bottom: $margin-xs;
    font-size: 14px;

    &.bold {
      color: $black;
      font-size: inherit;
    }

    .astric {
      font-size: 12px;
      color: $text-error;
      margin-left: $margin-sm;
    }
  }

  .inputField {
    border: 1px solid $Border-Color-1;
    box-shadow: 0px 9px 6px #c1bcbc33;
    color: $RoyalBlue;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 2px;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    &::placeholder {
      color: $grey-10;
    }
    &:focus,
    &:active {
      color: $RoyalBlue;
    }
  }

  .info {
    color: $grey;
  }
}
