@import "@assets/Sass/variables";

.title {
  font-family: $secondary-font;
  justify-content: center;
  text-align: center;
  flex: auto;
}
.content {
  font-weight: normal;
  font-size: 20px;
}
.action {
  margin-top: $padding-md * 2;
  font-family: $secondary-font;
}
.list {
  font-weight: normal;
}
.img {
  margin-top: $padding-xs * 2.5;
  height: 45px;
}
