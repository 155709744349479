@import "@assets/Sass/variables";

.sidebar {
  padding-left: $padding-lg;
  padding-right: $padding-md;
  background-color: $black-700;
  color: $white;

  .closeIcon {
    margin-left: $margin-xl * 4.8;
    svg {
      height: 26px;
    }
  }
  .subText {
    margin-top: $margin-md;
    font-size: $font-sm;
    font-weight: normal;
  }
  .listItems {
    flex-direction: column;
    li {
      list-style: none;
      position: relative;
      color: $white-100;
      padding-left: $padding-xs * 5;
      margin-bottom: $margin-xs;
      font-weight: normal;
      font-size: smaller;

      &::before {
        content: "\2022";
        color: $white;
        font-size: $font-xl;
        line-height: 0;
        position: absolute;
        left: 0;
        top: 7px;
      }
    }
  }
  .tagline {
    font-size: $font-lg;
    font-weight: bold;
    font-style: italic;
    font-family: $secondary-font;
  }
  .title {
    margin-top: $margin-lg;
    font-size: $font-md;
    font-weight: medium;
    color: $primary-color;
  }
  img {
    height: 50px;
  }
  .time {
    color: $white-100;
    font-size: $font-sm;
  }
  .accordion-item {
    background-color: $black-700;
    border: none;
  }
  .accordion-header {
    background-color: $black-700;
  }
  .accordion-button {
    background-color: $black-700;
    color: $white;
    font-size: $font-lg;
    box-shadow: none;
  }
  .accordion-button:not(.collapsed) {
    color: $primary-color;
    font-size: $font-lg;
    background-color: $black-700;
    box-shadow: none;
  }
  .accordion-body {
    background-color: $black-700;
    color: $white;
    flex-direction: column;
    border-top: none;
    padding-left: $margin-xl;
    font-weight: normal;
  }
  .option {
    font-weight: normal;
    font-size: small;
  }
  .content {
    font-weight: normal;
    font-size: smaller;
  }
}
