@import "@assets//Sass//Mixins";
@import "@assets/Sass/variables";

.loanCalulator-dark {
  background-color: $white;
  box-shadow: 0 0 5px 1px #00000021;

  .heading {
    color: $white;
    background-color: $primary-color;
    font-weight: 500;
    font-size: $font-md;
  }
  .font-size-sm {
    font-size: 0.8em;
  }
  .font-size-lg {
    font-size: 1.1em;
  }
  .font-weight-bold {
    font-weight: 700;
  }
  .vehicleBudgetWrapper {
    background-color: $secondary-color;

    label {
      color: $white;
      font-size: 14px;
    }

    .vehicleBudgetPrice {
      color: #110f3e;
      font-size: 60px;
      position: relative;
      border-radius: 2px;
      border: 2px solid #2a68b4;

      input.form-control-text {
        font-weight: 500;
        outline: 0;
        border: 0px;
        width: 100%;
        color: #110f3e;
        text-align: center;
        background-color: #fff;
        &-dp {
          outline: 0;
          border: 0px;
          width: 100%;
          color: #110f3e;
          text-align: center;
          border-radius: 3px;
          background-color: #fff;
          border: 2px solid #eeeeee;
        }
      }
    }
  }

  .isInline {
    background-color: $secondary-color;
    .vehicleBudgetPrice {
      font-size: 40px;
    }
    .vehicleBudgetWrapper {
      padding: 0;
    }
  }

  .formFieldWrapper {
    .form-group {
      margin: $margin-xs * 5 0px;
      label {
        display: block;
        font-size: 12px;
        margin-bottom: $margin-xs;
      }
      .form-label {
        color: black;
      }
    }

    .currencyInputText {
      position: relative;

      input[type="number"] {
        text-indent: 14px;
      }

      span {
        position: absolute;
        top: 10px;
        left: 14px;
      }
    }

    .disclaimer {
      font-size: 0.7rem;
      font-weight: 310;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .vehicleBudgetWrapper {
    .btn-default {
      color: $white;
      background-color: $RoyalBlue;
    }
    .btn-default:hover {
      background-color: $RoyalBlue1;
    }
  }
  label.text-bold {
    font-size: 1.3em;
    font-weight: bold;
    letter-spacing: 0.2px;
  }
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.inputField {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50%;
}

.buyCarBtn {
  background-color: $black-700;
}
.showTermsText {
  background-color: $secondary-color;
  font-weight: normal;
  font-size: $font-sm;
  color: $white;
}
