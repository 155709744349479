@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.heading-1 {
  color: $HeadingColor1;
  font-weight: 400;

  @include mq(xxl) {
    font-size: 60px;
    line-height: 79px;
  }
}

.vehicleBudgetPrice {
  input {
    width: 100%;
    text-align: center;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.insurance-modal {
  .modal-content {
    .modal-header {
      border-radius: 0px;
    }
    border-radius: 0px;
  }
}

.btn-outline {
  border: 2px solid $RoyalBlue;
  background: $white;
  color: $RoyalBlue;
  border-radius: 4px;
  min-height: 48px;
  box-shadow: none;
  box-shadow: 0px 3px 8px #00000033;
  font-size: 18px;
  transition: all 0.3s;
  &.reverse {
    background: $RoyalBlue;
    border-color: $white;
    color: $white;
    &:hover {
      background: $Blue;
    }
  }
  &:hover {
    border-color: $white;
    background: $LightBlue;
    color: $white;
  }
  @include mq(xs) {
    min-height: 40px;
    font-size: 14px;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
    @include mq(xs) {
      opacity: 1;
    }
    &:hover {
      background: $white;
      border-color: $LightBlue;
      color: $LightBlue;
    }
  }
  &.inventory-btn {
    min-height: auto;
    height: 44px;
    font-size: 14px;
  }
  &.small-font {
    font-size: 16px;
  }
}

.btn-outline-1 {
  @extend .btn-outline;
  border-color: #0c0b35;
  background-color: #3497fd;
  color: $white;
}

.btn-outline-custom-success {
  color: #05aa06;
  min-height: 40px;
  margin-top: -2px;
  box-shadow: none;
  background: $white;
  border-radius: 4px;
  transition: all 0.3s;
  border: 2px solid #05aa06;
  box-shadow: 0px 3px 8px #00000033;
  &:hover {
    color: $white;
    border-color: $white;
    background: #05aa06;
  }
  @include mq(xs) {
    min-height: 40px;
    font-size: 0.8em;
  }
}

.btn-save {
  border: 2px solid $Blue;
  border-radius: 4px;
  box-shadow: 0px 20px 6px #0000001a;
  background: $RoyalBlue;
  min-height: 64px;
  font-weight: 700;
  font-size: 18px;
  transition: all 0.3s;
  position: relative;
  span {
    z-index: 2;
    svg {
      width: 30px;
    }
  }
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    width: 10px;
    height: 100%;
    background: $Background-2;
    z-index: 1;
    transition: all 0.5s;
  }
  &:hover {
    background: #137ce9;
    &:before {
      background: #37f0c6;
    }
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: $RoyalBlue;
      &:before {
        background: $Background-2;
      }
    }
  }
  @include mq(xs) {
    min-height: 50px;
    font-size: 14px;
  }
}

.sidebar-middle-content {
  padding: $padding-md $padding-lg;
  background-color: $tertiary-color;
  @include mq(sm) {
    padding: $padding-sm;
  }
}
.ash-bg {
  background-color: $tertiary-color;
}

.pagination {
  ul {
    li {
      font-size: 20px;
      color: $Blue;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 $margin-xs;
      cursor: pointer;
      &:hover {
        color: $RoyalBlue;
      }
      &.active {
        background-color: $Blue;
        color: $white;
      }
      &.next {
        transform: rotate(180deg);
      }
    }
  }
}

.accordion-button:focus {
  box-shadow: none;
}

.color-display-box {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid $Border-Color-2;
  display: inline-block;
  margin-right: $margin-sm;
}

.filter-list {
  text-transform: capitalize;
  font-weight: normal;
  @include mq(xs) {
    font-size: 14px;
  }
  label {
    cursor: pointer;
  }
}

.form-check {
  .form-check-input {
    cursor: pointer;
    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    &:checked[type="radio"] {
      background-image: none;
    }
  }
  label {
    cursor: pointer;
  }
}

.form-select.custom {
  border: 1px solid $Border-Color-1;
  box-shadow: 0px 3px 6px $Box-Shadow-1;
  background: $white url("./Assets/Images/icon-dropdown.svg") no-repeat right
    0.5rem top 0.9rem;
  color: $HeadingColor1;
  height: 44px;
}

.mobile-drawer {
  transition: all 0.3s ease-out;
  z-index: 101;
  @include mq(md) {
    position: absolute;
    top: 0;
    right: -470px;
    width: 470px;
    &.open {
      right: 0;
      .toggle-button {
        left: 0;
        width: 35px;
      }
    }
  }
  @include mq(xs) {
    width: 315px;
    right: -315px;
  }

  .toggle-button {
    display: none;
    @include mq(md) {
      background-color: $secondary-color;
      width: 70px;
      height: 50px;
      position: absolute;
      left: -70px;
      top: 0;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 $padding-xs;
      justify-content: center;
      cursor: pointer;

      @include mq(xs) {
        width: 40px;
        left: -40px;
        padding: 0 2px;
      }

      span {
        @include mq(xs) {
          width: 12px;
        }
        i {
          font-size: 38px;
        }
      }

      svg {
        path {
          fill: $white;
          opacity: 1;
        }
      }
    }
  }
}

.flip-horizontally {
  transform: scaleX(-1);
}
.gradient-bg {
  background: transparent
    linear-gradient(180deg, #ffffff 0%, #3497fd 0%, #bbdcfe 64%, #ffffff 100%)
    0% 0% no-repeat padding-box;
}
.gradient-bg-reverse {
  background: $teal-100 0% 0% no-repeat padding-box;
}
.full-height {
  min-height: 85vh;
}
.btn-danger {
  background-color: #ff0000;
  border-color: #ff0000;
}
.text-danger {
  color: #ff0000 !important;
}

.custom-scroll {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: $Background-1;
  }
  &::-webkit-scrollbar-thumb {
    background: $grey01;
    border-radius: 10px;
  }
}

.text-overflow-rtl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
}
.gm-style img {
  max-width: 100%;
}
.blue-border {
  height: 15px;
  display: flex;

  @include mq(xs) {
    height: 10px;
  }

  div {
    flex: 1;
    &:first-child {
      background: transparent $gradient-blue;
    }
    &:last-child {
      background: transparent $gradient-blue;
    }
  }
}

.mobile-bottom-sticky {
  @include mq(xs) {
    left: 0;
    bottom: 0;
    padding: 20px;
    z-index: 100;
    position: fixed;
    background-color: $Blue;
  }
}

// idk why, but I am seeing an iframe with position fixed.
body > iframe {
  display: none !important;
}

.modal-content {
  border: none;
}
body {
  // checkbox color
  .css-ncoufy-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-ncoufy-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: $primary-color;
  }
}
