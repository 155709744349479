.form{
  :global {
    .MuiSlider-track {
      display: none;
    }
    
    .MuiSlider-mark {
      display: none;
    }
  }
}