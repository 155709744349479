@mixin mq($size) {
  @if $size == xxs {
    @media (max-width: 360px) {
      @content;
    }
  } @else if $size == xss {
    @media (max-width: 479px) {
      @content;
    }
  } @else if $size == xs {
    @media (max-width: 575.98px) {
      @content;
    }
  } @else if $size == sm {
    @media (max-width: 767.98px) {
      @content;
    }
  } @else if $size == md {
    @media (max-width: 991.98px) {
      @content;
    }
  } @else if $size == lg {
    @media (max-width: 1199.98px) {
      @content;
    }
  } @else if $size == xl {
    @media (max-width: 1399.98px) {
      @content;
    }
  } @else if $size == md-min {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $size == lg-min {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == xxl {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $size == xxl-1 {
    @media (min-width: 1800px) {
      @content;
    }
  } @else if $size == sm-md {
    @media (min-width: 767.98px) and (max-width: 991.98px) {
      @content;
    }
  } @else if $size == sm-lg {
    @media (min-width: 767.98px) and (max-width: 1199.98px) {
      @content;
    }
  } @else if $size == md-lg {
    @media (min-width: 991.98px) and (max-width: 1199.98px) {
      @content;
    }
  }
}

@mixin CardContainer {
  border-radius: 8px;
  margin-bottom: $margin-lg;
  overflow: hidden;
  padding: 0 $padding-md;
  background: $Background-1 0% 0% no-repeat padding-box;
}

@mixin Overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  cursor: pointer;
  background-color: $backdrop-color;
}

@mixin WhiteContainerBox {
  background: $white;
  margin-top: $margin-xs;
  padding: $padding-sm;
  border-radius: 4px;

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    strong,
    span {
      flex: 1;
    }
  }
}
