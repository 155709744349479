@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.container {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
  .contactForm {
    border: none;
    height: calc(100% - 40px);
    margin: $margin-md * 1.25 $margin-lg $margin-lg $margin-lg;
    @mq (sm) {
      margin: 0 0 $margin-lg 0;
    }
  }
  .contactProcess {
    height: 100%;
    border: none;
    min-height: 100vh;
  }
  .contactProcessCol {
    min-height: 100vh;
  }
}

.businessCard {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid $RoyalBlue;
  box-shadow: $card-box-shadow;
  transition: all 0.3s ease-in;
  a {
    transition: all 0.3s ease-in;
    color: $RoyalBlue;
  }
  &:hover {
    color: #fff;
    background-color: $RoyalBlue;
    border: 1px solid $Background-3;
    a {
      color: #fff;
    }
  }
}

.rightImage {
  $width: 300px;
  height: $width;
  border: 2px solid $white;
}

.imageContainer {
  opacity: 1;
  width: 100%;
  border-radius: 4px;
}

.moreReach {
  &Heading {
    opacity: 1;
    color: $black;
    font: normal normal normal 45px/59px Roboto;
  }
  &Text {
    opacity: 1;
    color: $Blue;
    line-height: 1.9em;
  }
}

.checkboxText {
  color: $Blue;
  font: normal normal medium 36px/60px Roboto;
}

.attentionHeading {
  color: $light-blue;
  font: normal normal normal 40px/60px Roboto;
}

.franchise {
  &Heading {
    color: $light-blue;
    font: normal normal normal 40px/18px Roboto;
    line-height: 1.2;
  }
  &Text {
    color: $Blue;
    font: normal normal normal 14px/18px Roboto;
  }
}

.contactEndText {
  color: $Blue;
  font: normal normal bold 30px/39px Roboto;
}

.textLg {
  font-size: $margin-lg - 2px;
}

.lineHeightLg {
  line-height: 1.3;
}

.sidebarHeader {
  @mq (sm) {
    margin-left: 30px;
  }
}
.contactHeading {
  color: $blue;
}
