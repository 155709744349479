@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-height: 100vh;

  .contactForm {
    border: none;
    height: calc(100% - 40px);
    margin: $margin-md * 1.25 $margin-lg $margin-lg $margin-lg;
    @mq (sm) {
      margin: 0 0 $margin-lg 0;
    }
  }
  .contactProcess {
    height: 100%;
    border: none;
    min-height: 100vh;
  }
  .contactProcessCol {
    min-height: 100vh;
  }
  .overlay {
    @include Overlay();
  }

  .businessCard {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid $RoyalBlue;
    box-shadow: $card-box-shadow;
    transition: all 0.3s ease-in;
    a {
      color: $RoyalBlue;
      transition: all 0.3s ease-in;
    }
    &:hover {
      color: #fff;
      border: 1px solid $Background-3;
      background-color: $RoyalBlue;
      a {
        color: #fff;
      }
    }
  }
}

.imageContainer {
  opacity: 1;
  width: 100%;
  height: 300px;
  border-radius: 4px;
  box-shadow: $card-box-shadow;
}
