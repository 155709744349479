/*
    color palette:
        - color-name - actual color
        - color-name-100; lighest
        - color-name-200; brighter than 100
        - color-name-300; brighter than 200
        ........
        ........
        - color-name-900; lighter than color-name

        the palette should not go over 900.
        No longer use of -1, -2 etc, 
        refer to the material ui color palette: https://materialui.co/colors/

        I'd eventually like to go with HSL instead of hash codes, as it's easy to manage the palette
*/

// teal color
$teal: #17c0cc;
$teal-50: #f0fcfc;
$teal-100: #b2eeee;
$teal-200: #17c0cc19;
$teal-400: #b2eeee98;
$teal-500: #7defec;
$teal-900: #035f77;

// white
$white-100: #b5b3b3;

// black
$black-50: #313028;
$black-100: #212529;
$black-200: #313028;
$black-700: #2a2f32;

// blue

// red
$red: #f97171;
$red-900: #ff0000;

// gray
$gray: #707070;
$gray-50: #eeeeee;
$gray-100: #777777;
$gray-200: #4d4f5c;
$gray-700: #6897b11f;

/*
theme colors, try to use theme colors as much as possible, no longer use of the color names directly.
primary-
secondary-
tertiary-
danger-
warning-
success-
error-
*/

$primary-color: $teal;
$secondary-color: $teal-900;
$tertiary-color: $teal-50;

$primary-box-shadow: 0px 3px 8px #00000029;

$primary-border-color: #eeeeee;

// PRIMARY COLORS
// $primary-color: #2a2f32;
// $secondary-color: #035f77;
// $tertiary-color: #7defec;
// $quaternary-color:#7defec; This should be discussed with the design team
$quinary-color: #b2eeee;
// $senary:#b2eeee;This should be discussed with the design team

// common color
$white: #ffffff;
$black: #000000;

// ALERT COLORS
$primary-alert: #f72424;
$secondary-alert: #f97171;

// GRAYSCALE
$primary-gray: #eeeeee;
$secondary-gray: #dddddd;
$tertiary-gray: #bbbbbb;
$quaternary-gray: #b8b8b8;
$quinary-gray: #777777;
$senary-gray: #454545;
