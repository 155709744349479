@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.title {
  color: $Blue;
}

:global {
  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
}

.desktop {
  .swiperContainer {
    height: 600px;
    padding-left: $padding-xl * 3;
    padding-right: $padding-xl * 3;
    @include mq(lg) {
      padding-left: 0;
      padding-right: 0px;
    }
    @include mq(md) {
      padding-left: 0;
      padding-right: 0px;
    }
    @include mq(sm) {
      padding-left: 0;
      padding-right: 0px;
    }
  }

  img {
    height: 450px;
  }
}
