@import "@assets/Sass/variables";
@import "@assets/Sass/Mixins";

.footerWrapper {
  background-color: $black-50;
  justify-content: center;
  .footerTop {
    justify-content: space-between;
    padding-right: $padding-xl * 4;
    padding-left: $padding-lg;

    @include mq(md) {
      justify-content: center;
      padding-right: 0;
      padding-left: 0;
    }
  }
  .footLogoImg {
    width: 25%;
    @include mq(md) {
      width: 100%;
    }
  }
  img {
    height: 70px;

    @include mq(md) {
      margin-left: 0;
    }
  }
  h5 {
    color: $white;
    @include mq(md) {
      margin-left: 0;
      margin-top: $margin-sm;
    }
  }
  p {
    font-weight: normal;
    color: $teal-500;

    @include mq(md) {
      margin-left: 0;
    }
  }
  li {
    list-style: none;
    font-weight: normal;
    color: $teal-500;

    @include mq(md) {
      margin-left: 0;
    }
  }
  h1 {
    font-size: $font-lg * 3;
    font-family: $secondary-font;

    @include mq(md) {
      font-size: $font-lg * 2;
    }
  }
}
.tagline {
  background-color: $black;
  opacity: 1;
  color: $white;
  display: flex;
  justify-content: space-between;

  img {
    margin-right: $margin-xl;
    margin-top: $margin-md;
    height: 20px;

    @include mq(md) {
      margin-right: $margin-lg;
      margin-top: $margin-sm;
    }
  }
}

.footCopyright {
  font-style: normal;
  font-size: 14px;
  margin: $margin-md 0 $margin-sm * 1.5;
  color: $white;
  text-align: left;
  font-weight: 200;
  @include mq(md) {
    text-align: center;
    padding-top: 0;
    margin: 0;
  }
}

.socialLinks {
  font-weight: normal;
  color: $teal-500;
  margin-left: $margin-xl;
}
