@import "@assets/Sass/variables";

.auth {
  color: $white;

  .link {
    color: $primary-color;
    font-size: $font-md;
    &:hover {
      color: $red-orange;
    }
  }
}

.sidebar {
  width: 300px;
  height: 100%;
  background-color: $black-700;
  color: $white;
  position: fixed;
  right: 0;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: $padding-lg;
}
.sidebar::-webkit-scrollbar {
  width: 8px;
}
.sidebar::-webkit-scrollbar-track {
  background: $white;
}
.sidebar::-webkit-scrollbar-thumb {
  background: $black;
}

.searchIcon {
  padding: $padding-sm;
}
